import React from 'react';
import { Link } from 'gatsby';
import Section from 'react-bulma-components/lib/components/section';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import { renderText, renderHtml } from 'utils/renderHelpers';
import classNames from 'classnames/bind';
import styles from './FinalistLongList.module.scss';
const cx = classNames.bind(styles);
export default ({ primary, items }) => {
  return (
    <Section
      className={`${primary.theme || `black`} ${cx({
        section: true,
        [primary.theme]: true
      })}`}
    >
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(primary.heading, 'h4')}
          </Columns.Column>
          {items.map((item, idx) => {
            let { author, name, description } = item;
            let doc;
            if (author) {
              doc = author.document[0];
              name = {
                text: `${doc.data.first_name.text} ${doc.data.last_name.text}`
              };
            }
            return (
              <Columns.Column
                key={`fll-${idx}`}
                mobile={{ size: 6 }}
                tablet={{ size: 4 }}
                desktop={{ size: 3 }}
              >
                {author ? (
                  <Link
                    to={`/${doc.slug}`}
                    title={name.text}
                    className={styles.author}
                  >
                    {renderText(name, 'span')}
                  </Link>
                ) : (
                  <h2>{name.text}</h2>
                )}
                {renderHtml(description, 'div', styles.description)}
              </Columns.Column>
            );
          })}
        </Columns>
      </Container>
    </Section>
  );
};
